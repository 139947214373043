import { graphql } from 'gatsby'
import React from 'react'

import { capitalize } from '../../lib/string'

import Acronym from '../components/acronym'
import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import FragrancesPrice from '../components/fragrances-price'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import Rating from '../components/rating'
import SEO from '../components/seo'

import style from './fragrance.module.css'


export const query = graphql`
  query FragranceTemplateQuery(
    $fragranceId: String!
   ) {
    ...SiteInfo
    results: postgres {
      fragrance: fragranceById(id: $fragranceId) {
        ...Fragrance
        brand: brandByBrand {
          ...Brand
          country: countryByCountry { ...Country }
        }
        clock { ...Clock }
        year: yearByYear { ...Year }
        accords: accordsList(threshold: 1, limiter: 3) {
          ...Accord
        }
        family: familiesList(limiter: 1) { ...Family }
        noses: fragrancesNosesByFragranceList {
          nose: noseByNose { ...Nose }
        }
        notes: fragrancesNotesByFragranceList(
          orderBy: [ LAYER_DESC, KEY_DESC, NOTE_ASC ]
        ) {
          key
          layer
          note: noteByNote { ...Note }
        }
        othersBrands: othersBrandsList(first: 3) { ...Fragrance }
        othersClocks: othersClocksList(first: 3) { ...Fragrance }
        othersFamilies: othersFamiliesList(first: 3) { ...Fragrance }
        othersSeasons: othersSeasonsList(first: 3) { ...Fragrance }
        othersYears: othersYearsList(first: 3) { ...Fragrance }
        seasons: seasonsList { ...Season }
        similars: similarsList(first: 3) { ...Fragrance }
        similarsNoses: similarsNosesList(first: 3) { ...Fragrance }
        similarsYears: similarsYearsList(first: 3) { ...Fragrance }
      }
      layers: allLayersList { ...Layer }
    }
  }
`

export default ({ data }) => {
  const { siteMetadata } = data.site
  const { links, meta } = siteMetadata
  const { entity, id } = meta
  const { fragrance, layers } = data.results

  const layerSortDesc = (a, b) => {
    if (a.id < b.id) return 1
    if (a.id > b.id) return -1
    return 0
  }

  const keywords = fragrance.caption.split(/\W+/)
  const trail = [
    {
      name: entity.plural,
      slug: links.fragrances,
    },
    {
      name: [fragrance.brand.name, fragrance.name].join(' '),
      slug: fragrance.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: fragrance.about,
    image: fragrance.image,
    keywords,
    name: fragrance.caption,
    product: fragrance,
    slug: fragrance.slug,
  }

  return (
    <Layout>
      <SEO
        description={fragrance.about}
        keywords={keywords}
        schema={schema}
        title={fragrance.caption}
      />
      <Breadcrumb trail={trail} />

      <Heading basement={false}>{fragrance.title}</Heading>
      <Heading level={2} basement={false}>
        Eau de {capitalize(fragrance.density)} {' '}
        (Ed{capitalize(fragrance.density[0])})
      </Heading>
      <Heading level={3}>
        {fragrance.size} {' '}
        <Acronym title="fluid ounces">fl oz</Acronym> {' '}
        {capitalize(fragrance.format)}
      </Heading>

      <Paragraph>
        <Image
          alt={fragrance.caption}
          amazonSize={500}
          className={style.image}
          src={fragrance.image}
        />
      </Paragraph>

      {/*
      <Paragraph>
        <div style={{margin: '0 auto', maxHeight: '200px', maxWidth: '300px'}}>
          <Image src={fragrance.imageSqip.dataURIBase64} />
        </div>
      </Paragraph>
      */}

      {/*
      <div>
        Colors:
        {Object.keys(fragrance.imageSqip.palette).map(swatch => (
          <span
            style={{
              backgroundColor: fragrance.imageSqip.palette[swatch].hex,
              border:'1px solid #eee',
              display: 'inline-block',
              height:20,
              width:20,
            }}
          >
            &nbsp;
          </span>
        ))}
      </div>
      */}

      {fragrance.stock && (
        <FragrancesPrice disclose={true} fragrance={fragrance} unit={true} />
      )}

      <Paragraph>
        {fragrance.stock ? 'In Stock' : (<strong>Out of Stock</strong>)}
      </Paragraph>

      <Paragraph>
        {fragrance.about}
      </Paragraph>

      <Rating count={fragrance.ratings} rating={fragrance.rating} />
      <br/>

      <div>
        <Link
          affiliateId={id.affiliate}
          button={true}
          href={fragrance.link}
          rel="sponsored"
        >
          Shop Now
        </Link>
        {/* @TODO
          https://advertising.amazon.com/resources/ad-policy/en/brand-usage
          text (14pt or 11.5pt, "at" is good but "on" is bad):
            - Shop now at Amazon.com
            - Shop now
            - Learn more
            - See details
        */}
        <Paragraph id="amazon-ftc-affiliate-disclosure" leading="solid">
          <small>
          We may receive small commissions for purchases made through the
          above affiliate link, at no additional cost to you.
          </small>
        </Paragraph>
      </div>

      <Paragraph>
        Made in {' '}
        <Link href={fragrance.brand.country.slug}>
          {fragrance.brand.country.name}
        </Link>
      </Paragraph>

      <Paragraph>
        More from {' '}
        <Link href={fragrance.brand.slug}>
          {fragrance.brand.name}
        </Link>
      </Paragraph>

      {fragrance.year && (
        <Paragraph>
          Year: {' '}
          <Link href={fragrance.year.slug}>
            {fragrance.year.id}
          </Link> {' '}
        </Paragraph>
      )}

      {!!fragrance.notes.length && (
        <Paragraph>
          Time of Day: {' '}
          <Link href={fragrance.clock.slug}>
            {fragrance.clock.name}
          </Link>
        </Paragraph>
      )}

      {!!fragrance.seasons.length && (
        <div>
          Seasons:
          <List>
            {fragrance.seasons.map(season => (
              <ListItem key={season.id}>
                <Link href={season.slug}>
                  {season.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {!!fragrance.family.length && (
        <div>
          Family: {' '}
          <Link href={fragrance.family[0].slug}>
            {fragrance.family[0].name}
          </Link>
          <br/><br/>
        </div>
      )}

      {!!fragrance.accords.length && (
        <div>
          Accords:
          <List>
            {fragrance.accords.map(accord => (
              <ListItem key={accord.id}>
                <Link href={accord.slug}>
                  {accord.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {!!fragrance.notes.length && (
        <div>
          Notes:
          <br/>
          <small>Key notes are in <strong>bold</strong>.</small>
          <List>
            {layers.sort(layerSortDesc).map(layer => (
              <ListItem key={layer.id}>
                <Link href={layer.slug}>
                  {capitalize(layer.name)} Notes
                </Link>
                <List basement={false}>
                  {fragrance.notes.map(note => (layer.id === note.layer) && (
                    <ListItem key={note.note.id}>
                      <Link href={note.note.slug}>
                        {note.key ? (
                          <strong>{note.note.name}</strong>
                        ) : (
                          <>{note.note.name}</>
                        )}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {!!fragrance.noses.length && (
        <div>
          Noses:
          <List>
            {fragrance.noses.map(({nose}) => (
              <ListItem key={nose.id}>
                <Link href={nose.slug}>
                  {nose.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      )}

      {!!fragrance.notes.length && (
        <>
          <Heading level={2}>
            Most Similar {entity.plural}
          </Heading>
          <FragrancesList fragrances={fragrance.similars} />
          {/* @TODO Explain why these are similar to visitors. */}
        </>
      )}

      {(!!fragrance.notes.length && !!fragrance.othersClocks.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} for Other Times of Day
          </Heading>
          <FragrancesList fragrances={fragrance.othersClocks} />
        </>
      )}

      {(!!fragrance.seasons.length && !!fragrance.othersSeasons.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} for Other Seasons
          </Heading>
          <FragrancesList fragrances={fragrance.othersSeasons} />
        </>
      )}

      {!!fragrance.notes.length && (
        <>
          <Heading level={2}>
            Similar {entity.plural} from Other Brands
          </Heading>
          <FragrancesList fragrances={fragrance.othersBrands} />
        </>
      )}

      {(!!fragrance.family.length && !!fragrance.othersFamilies.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} from Other Families
          </Heading>
          <FragrancesList fragrances={fragrance.othersFamilies} />
        </>
      )}

      {(!!fragrance.noses.length && !!fragrance.similarsNoses.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} by Same Nose
          </Heading>
          <FragrancesList fragrances={fragrance.similarsNoses} />
        </>
      )}

      {(fragrance.year && !!fragrance.similarsYears.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} from {fragrance.year.id}
          </Heading>
          <FragrancesList fragrances={fragrance.similarsYears} />
        </>
      )}

      {(fragrance.year && !!fragrance.othersYears.length) && (
        <>
          <Heading level={2}>
            Similar {entity.plural} from Other Years
          </Heading>
          <FragrancesList fragrances={fragrance.othersYears} />
        </>
      )}

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
