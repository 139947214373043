import PropTypes from 'prop-types'
import React from 'react'

import style from './acronym.module.css'


const Acronym = ({ children, title }) => (
  <abbr className={style.acronym} title={title}>
    {children}
  </abbr>
)
Acronym.propTypes = {
  children: PropTypes.node.isRequired,
  title:    PropTypes.string.isRequired,
}

export default Acronym
